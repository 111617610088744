import { StepIconProps, styled } from '@mui/material';

import theme from '../../styles/theme';

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean };
}>(() => ({
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: theme.palette.primary.dark,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: theme.palette.typography.light,
  },
}));

export const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
};

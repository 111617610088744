export const ENDPOINTS = {
  LANGUAGE: {
    LIST: '/language-list',
    TRANSLATIONS: '/translation-list',
  },
  FORMS: {
    START: '/create-session',
    END: '/complete-survey',
    LIST: '/survey-list',
    SUBMIT: '/post-survey-step',
    RESULT: '/result',
  },
};

import { StepConnector, stepConnectorClasses, styled } from '@mui/material';
import theme from '../../styles/theme';

export const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: 'none',
      background: theme.palette.primary.dark,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: 'none',
      background: theme.palette.primary.dark,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: 'none',
    height: 3,
    background: theme.palette.typography.light,
  },
}));

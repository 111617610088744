import { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import CheckBoxForm from './CheckBoxForm';
import { QuestionT } from '../../types';

import { form } from '../../api';
import { QontoConnector } from '../stepper/QontoConnector';
import { QontoStepIcon } from '../stepper/QontoStepIcon';
import AlertNotification from '../AlertNotification';
import { TranslationsContext } from '../../context/translations';
import { useLocation } from 'wouter';

const classes = {
  dataDisplay: {
    width: '80%',
    margin: '0 auto',
  },
  stepper: {
    marginBottom: '64px',
  },
};

export const EstimateForm = () => {
  const [, navigate] = useLocation();

  const { translationData, selectedLanguage } = useContext(TranslationsContext);
  const [formData, setFormData] = useState<QuestionT[] | undefined>();

  const [activeStep, setActiveStep] = useState<number>(0);

  const [notify, setNotify] = useState<any>({
    isOpen: false,
    message: '',
    type: '',
  });

  useEffect(() => {
    if (selectedLanguage)
      form.all(selectedLanguage.id).then((res) => setFormData(res.data));
  }, [selectedLanguage]);

  const FormSchema = Yup.array().of(Yup.object());

  const initValues = localStorage.getItem('values')
    ? JSON.parse(localStorage.getItem('values') || '')
    : formData &&
      formData.reduce(
        (obj: any, cur: any) => ({
          ...obj,
          [cur.id]: cur.isMulti ? [] : '',
        }),
        {}
      );

  const sendStepData = (
    step_id: number,
    step_data: any,
    formData: QuestionT[]
  ) => {
    // pack data the way server expects it
    const dataToSend = {
      questionId: step_id,
      answer:
        Array.isArray(step_data) && step_data.length === 0
          ? null
          : step_data
          ? step_data
          : null,
    };

    form
      .submit(dataToSend)
      .then((res) => {
        if (res.status === 200) {
          if (activeStep < formData.length - 1) {
            setActiveStep(activeStep + 1);
          } else {
            form.end().then(() => {
              navigate(`/result/${localStorage.getItem('SESSION_ID')}`);
            });
          }
        }
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message: 'Error occured. Please, try again later.',
          type: 'error',
        });
      });
  };

  const handleSubmit = (data: any) => {
    console.log(data);
  };

  const translateText = (keyword: string) => {
    if (!translationData) return;
    const text = translationData.find((el: any) =>
      el.keyword.includes(keyword)
    );

    if (!text) return text;

    return text.value;
  };

  if (!formData || !translationData) return null;

  return (
    <>
      <Grid item sx={classes.dataDisplay}>
        <Formik
          onSubmit={(data: any) => {
            handleSubmit(data);
          }}
          enableReinitialize={true}
          validationSchema={FormSchema}
          initialValues={initValues}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container>
                <CheckBoxForm
                  answers={formData[activeStep].answers}
                  title={formData[activeStep].questionLanguages[0].title}
                  description={
                    formData[activeStep].questionLanguages[0].description
                  }
                  setFieldValue={setFieldValue}
                  id={formData[activeStep].id}
                  isMulti={formData[activeStep].multiple}
                  values={values}
                />
              </Grid>
              <Grid
                container
                gap='2rem'
                justifyContent='center'
                sx={{ padding: '40px' }}
              >
                <Button
                  disabled={activeStep === 0}
                  variant='outlined'
                  // color='error'
                  onClick={() =>
                    activeStep > 0 && setActiveStep(activeStep - 1)
                  }
                >
                  {translateText('back_button')}
                </Button>
                <Button
                  // button has to be disabled only in case where form expects only one input
                  disabled={
                    (!formData[activeStep].multiple &&
                      values[formData[activeStep].id].length === 0) ||
                    activeStep === formData.length
                  }
                  sx={{ color: 'white' }}
                  variant='contained'
                  onClick={() => {
                    localStorage.setItem('values', JSON.stringify(values));
                    sendStepData(
                      formData[activeStep].id,
                      values[formData[activeStep].id],
                      formData
                    );
                  }}
                >
                  {activeStep === formData.length - 1
                    ? translateText('submit_button') || 'Submit'
                    : translateText('next_button')}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item sx={classes.dataDisplay}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
          sx={classes.stepper}
        >
          {formData.map((el: QuestionT) => (
            <Step key={el.id}>
              <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <AlertNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

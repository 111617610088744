import { AxiosResponse } from 'axios';
import { ENDPOINTS } from '../constants';
import { axios } from '../services';
import { LanguageT, TranslationT } from '../types';

export const lang = {
  all: (): Promise<AxiosResponse<LanguageT[], any>> =>
    axios(ENDPOINTS.LANGUAGE.LIST),
  translations: (
    languageId: number
  ): Promise<AxiosResponse<TranslationT[], any>> =>
    axios(ENDPOINTS.LANGUAGE.TRANSLATIONS, {
      headers: {
        'X-Language-Id': languageId,
      },
    }),
};

import { AxiosResponse } from 'axios';
import { ENDPOINTS } from '../constants';
import { axios } from '../services';
import { FormSubmitDataT, QuestionT, SubmitResponseT, ResultT } from '../types';
export const form = {
  start: (
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    companyName: string
  ): Promise<AxiosResponse<{ session: string }, any>> =>
    axios.post(
      ENDPOINTS.FORMS.START,
      {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        companyName: companyName,
      },
      {
        headers: {
          'X-Language-Id': localStorage.getItem('LANG_ID'),
        },
      }
    ),
  end: (): Promise<AxiosResponse<void, any>> =>
    axios.post(ENDPOINTS.FORMS.END, null, {
      headers: {
        'X-Session-Id': localStorage.getItem('SESSION_ID'),
        'X-Language-Id': localStorage.getItem('LANG_ID'),
      },
    }),
  all: (languageId: number): Promise<AxiosResponse<QuestionT[], any>> =>
    axios(ENDPOINTS.FORMS.LIST, {
      headers: {
        'X-Session-Id': localStorage.getItem('SESSION_ID'),
        'X-Language-Id': languageId,
      },
    }),
  submit: (
    formData: FormSubmitDataT
  ): Promise<AxiosResponse<SubmitResponseT, any>> =>
    axios.post(ENDPOINTS.FORMS.SUBMIT, formData, {
      headers: {
        'X-Session-Id': localStorage.getItem('SESSION_ID'),
        'X-Language-Id': localStorage.getItem('LANG_ID'),
      },
    }),
  result: (sessionId: string): Promise<AxiosResponse<ResultT, any>> =>
    axios(ENDPOINTS.FORMS.RESULT, {
      headers: {
        'X-Language-Id': localStorage.getItem('LANG_ID'),
      },
      params: {
        session_id: sessionId,
      },
    }),
};

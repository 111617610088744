import { FiberManualRecord } from '@mui/icons-material';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Skeleton,
} from '@mui/material';
import { FC } from 'react';

interface DevList {
  requiredDevelopers: {
    id: number;
    position: number;
    developerLanguages: {
      title: string;
    }[];
  }[];
}

const DeveloperList: FC<DevList> = ({ requiredDevelopers }) => {
  const emptyArray = requiredDevelopers.length === 0;
  return (
    <>
      <Typography sx={{ mt: 4, mb: 2 }}>
        {!emptyArray ? 'Required Developers:' : <Skeleton />}
      </Typography>
      <Divider />
      <List>
        {!emptyArray
          ? requiredDevelopers.map((developer) => {
              //Generate random color
              const color =
                Math.floor(Math.random() * 256) +
                ',' +
                Math.floor(Math.random() * 256) +
                ',' +
                Math.floor(Math.random() * 256);

              return (
                <ListItem key={developer.id}>
                  <ListItemIcon>
                    <FiberManualRecord
                      sx={{
                        background: `rgba(${color},0.1)`,
                        borderRadius: '50%',
                        padding: '6px',
                        color: `rgba(${color},1)`,
                        border: `0.5px solid rgba(${color},0.3)`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={developer.developerLanguages[0].title}
                  />
                </ListItem>
              );
            })
          : new Array(2).fill(0).map((v, index) => {
              return (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Skeleton variant='circular' width={40} height={40} />
                  </ListItemIcon>
                  <ListItemText primary={<Skeleton />} />
                </ListItem>
              );
            })}
      </List>
    </>
  );
};
export default DeveloperList;

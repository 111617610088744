import { useContext, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { form } from '../../api';
import AlertNotification from '../AlertNotification';
import { SurveyContext } from '../../context/survey';
import theme from '../../styles/theme';

const classes = {
  dataDisplay: {
    width: '80%',
    margin: '0 auto',
  },
  stepper: {
    marginBottom: '64px',
  },
  formContainer: {
    minHeight: '350px',
    width: '100%',
  },
  emailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
  },
  emailInputContainer: {
    width: '100%',
  },
  input: {
    width: '350px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
  button: {
    color: 'white',
  },
  errorMessage: {
    // added negative margin for simplest way to keep error in place w/o moving other elements
    marginBottom: '-24px',
    color: 'red',
  },
};

type AlertNotification = {
  isOpen: boolean;
  message: string;
  type: string;
};

export const EmailForm = () => {
  const { setSessionId } = useContext(SurveyContext);
  const [loading, setLoading] = useState<boolean>(false);
  const mobileOnly = useMediaQuery(theme.breakpoints.down('sm'));

  const [notify, setNotify] = useState<AlertNotification>({
    isOpen: false,
    message: '',
    type: '',
  });

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    companyName: '',
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .max(50)
      .email('Please enter the correct email address.')
      .required('Email is required.'),
    firstName: Yup.string().max(50).required('First Name is required.'),
    lastName: Yup.string().max(50).required('Last Name is required.'),
  });

  const handleSubmit = (
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    companyName: string
  ) => {
    setLoading(true);
    form
      .start(email, firstName, lastName, phone, companyName)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setNotify({
            isOpen: true,
            message: 'Success',
            type: 'success',
          });
          setSessionId(res.data.session);
        }
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message: 'Error',
          type: 'error',
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Grid item sx={classes.dataDisplay}>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={(values) =>
            handleSubmit(
              values.email,
              values.firstName,
              values.lastName,
              values.phone,
              values.companyName
            )
          }
        >
          {({ isValid }) => (
            <Form>
              <Grid
                container
                direction='column'
                justifyContent='space-evenly'
                alignItems='center'
                sx={classes.formContainer}
              >
                <Grid item>
                  <Typography
                    variant={mobileOnly ? 'body1' : 'h1'}
                    m={2}
                    align='center'
                  >
                    App estimation
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    m={2}
                    align='center'
                    width={{ md: '60%', sm: '100%', xs: '100%' }}
                    margin={'20px auto'}
                  >
                    Welcome to the Quantox estimation website. Through this
                    short form we will collect the details about your app and
                    give you an overall estimate
                  </Typography>
                </Grid>
                <Grid item sx={classes.emailWrapper}>
                  <Field
                    name='firstName'
                    id='firstName'
                    variant='outlined'
                    label='First Name'
                    type='text'
                    sx={classes.input}
                    as={TextField}
                  />
                  <ErrorMessage
                    name='firstName'
                    render={(errMsg) => (
                      <Typography sx={classes.errorMessage}>
                        {errMsg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item sx={classes.emailWrapper}>
                  <Field
                    name='lastName'
                    id='lastName'
                    variant='outlined'
                    label='Last Name'
                    type='text'
                    sx={classes.input}
                    as={TextField}
                  />
                  <ErrorMessage
                    name='lastName'
                    render={(errMsg) => (
                      <Typography sx={classes.errorMessage}>
                        {errMsg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item sx={classes.emailWrapper}>
                  <Field
                    name='email'
                    id='email'
                    variant='outlined'
                    label='Email'
                    type='email'
                    sx={classes.input}
                    as={TextField}
                  />
                  <ErrorMessage
                    name='email'
                    render={(errMsg) => (
                      <Typography sx={classes.errorMessage}>
                        {errMsg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item sx={classes.emailWrapper}>
                  <Field
                    name='companyName'
                    id='companyName'
                    variant='outlined'
                    label='Company Name'
                    type='text'
                    sx={classes.input}
                    as={TextField}
                  />
                  <ErrorMessage
                    name='companyName'
                    render={(errMsg) => (
                      <Typography sx={classes.errorMessage}>
                        {errMsg}
                      </Typography>
                    )}
                  />
                  <Typography variant='subtitle2'>Optional</Typography>
                </Grid>
                <Grid item sx={classes.emailWrapper}>
                  <Field
                    name='phone'
                    id='phone'
                    variant='outlined'
                    label='Phone'
                    type='text'
                    sx={classes.input}
                    as={TextField}
                  />
                  <ErrorMessage
                    name='phone'
                    render={(errMsg) => (
                      <Typography sx={classes.errorMessage}>
                        {errMsg}
                      </Typography>
                    )}
                  />
                  <Typography variant='subtitle2'>Optional</Typography>
                </Grid>

                <Grid item>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type='submit'
                      variant='contained'
                      disabled={!isValid}
                      sx={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <AlertNotification notify={notify} setNotify={setNotify} />
    </>
  );
};

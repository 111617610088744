import { createContext } from 'react';
import { LanguageT, TranslationT } from '../types';

interface TranslationsContextType {
  translationList: LanguageT[] | undefined;
  selectedLanguage: LanguageT | undefined;
  translationData: TranslationT[] | undefined;
}

export const TranslationsContext = createContext<TranslationsContextType>(
  {} as TranslationsContextType
);
export const TranslationsProvider = TranslationsContext.Provider;

import { Box, styled } from '@mui/material';
import React from 'react';

const FormContainer: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};

const Container = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '3rem',
  gap: '4rem',
  marginInline: 'auto',
  textAlign: 'center',
  opacity: 0,

  '@keyframes show': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },

  animation: 'show 1000ms ease forwards',
});

export default FormContainer;

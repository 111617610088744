import { useContext } from 'react';
import { EmailForm } from '../components/forms/EmailForm';

import { EstimateForm } from '../components/forms/EstimateForm';
import { SurveyContext } from '../context/survey';

export default function HomePage() {
  const { sessionId } = useContext(SurveyContext);

  return !sessionId ? <EmailForm /> : <EstimateForm />;
}

import { Grid, Button } from '@mui/material';
import { Redirect, Route, Switch } from 'wouter';

import logo from './images/qlogo.png';
import Result from './components/result/Result';
import theme from './styles/theme';
import { TranslationsProvider } from './context/translations';
import { useTranslations } from './hooks/useTranslations';
import { useEffect, useState } from 'react';
import { ServeyProvider } from './context/survey';
import HomePage from './pages/HomePage';
import axios from 'axios';

const classes = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0px 24px 0px',
    backgroundColor: theme.palette.common.white,
    border: '1.5px solid #F3F2EC',
    boxShadow: '0px 16px 64px rgba(14, 37, 62, 0.02)',
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

const App = () => {
  const { translationList, selectedLanguage, translationData } =
    useTranslations();

  const [sessionId, setSessionId] = useState(
    localStorage.getItem('SESSION_ID')
  );

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem('SESSION_ID', sessionId);
    }
  }, [sessionId]);

  return (
    <TranslationsProvider
      value={{
        translationList,
        selectedLanguage,
        translationData,
      }}
    >
      <ServeyProvider value={{ sessionId, setSessionId }}>
        <Grid container direction='column'>
          <Grid item sx={classes.header}>
            <Grid item sx={classes.logo}>
              <Button href='https://quantox.com'>
                <img src={logo} alt='logo' />
              </Button>
            </Grid>
          </Grid>
          <Switch>
            <Route path='/' component={HomePage} />
            <Route path='/result/:id' component={Result} />
            <Redirect to='/' />
          </Switch>
        </Grid>
      </ServeyProvider>
    </TranslationsProvider>
  );
};

export default App;
[];

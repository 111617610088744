import type { FC } from 'react';
import {
  Snackbar,
  Button,
  Typography,
  Divider,
  AlertTitle,
  Alert,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import theme from '../styles/theme';

const classes = {
  snackbarContainer: {
    borderRadius: '32px',
    border: `1.5px solid ${theme.palette.background.default}`,
    boxShadow:
      '0 15px 20px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  },
  alertMain: {
    backgroundColor: 'white',
    borderRadius: '32px',
    width: '544px',
    padding: '5px 22px 5px 5px',
    '& .MuiAlert-icon': {
      padding: '0px 0px 0px 0px',
    },
    '& .MuiAlert-message': {
      padding: '0px 0px 0px 0px',
      marginTop: '4px',
    },
    '& .MuiAlert-action': {
      padding: '0px 0px 0px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0px',
      borderRadius: '0px',
    },
  },
  alertTitleTypography: {
    fontWeight: 500,
  },
  alertTextTypography: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.typography?.dark,
  },
  buttonTypography: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWight: 500,
    color: theme.palette.typography?.light,
    textTransform: 'uppercase',
  },
  buttonClose: {
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  divider: {
    height: '30px',
  },
};

export interface AlertNotificationProps {
  notify: any;
  setNotify: any;
}

const AlertNotification: FC<AlertNotificationProps> = (
  props: AlertNotificationProps
) => {
  const { notify, setNotify } = props;
  const handleClose = () => {
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <Snackbar
      sx={classes.snackbarContainer}
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert
        severity={notify.type}
        sx={classes.alertMain}
        iconMapping={{
          success: <CheckCircleIcon sx={{ width: '56px', height: '56px' }} />,
          error: <ErrorIcon sx={{ width: '56px', height: '56px' }} />,
        }}
        action={
          <>
            <Divider
              orientation='vertical'
              light
              variant='middle'
              sx={classes.divider}
            />
            <Button
              onClick={handleClose}
              color='inherit'
              size='small'
              sx={classes.buttonClose}
            >
              <Typography sx={classes.buttonTypography}>close</Typography>
            </Button>
          </>
        }
      >
        <AlertTitle>
          <Typography variant='body1' sx={classes.alertTitleTypography}>
            {capitalizeFirstLetter(notify.type)}
          </Typography>
        </AlertTitle>
        <Typography sx={classes.alertTextTypography}>
          {notify.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default AlertNotification;

import { createTheme } from '@mui/material/styles';

// type CustomTheme = {
//   [Key in keyof typeof theme]: (typeof theme)[Key];
// };

// declare module '@mui/material/styles/createTheme' {
//   type Theme = CustomTheme;
//   type ThemeOptions = CustomTheme;
// }

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    typography: Palette['primary'];
    gradients: { green: string; grey: string };
  }
  interface PaletteOptions {
    typography: PaletteOptions['primary'];
    gradients: { green: string; grey: string };
  }
}

const theme = {
  palette: {
    primary: {
      main: '#9acb42',
      dark: '#569127',
      light: '#f0ffd3',
    },
    typography: {
      main: '#6E6F72',
      dark: '#000000',
      light: '#9FB9C9',
    },
    gradients: {
      green: 'linear-gradient(124.78deg, #e0efc5 1.37%, #569127 101.37%)',
      grey: 'linear-gradient(180deg, #9FB9C9 0%, #424242 100%)',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
  },

  typography: {
    main: '#6E6F72',
    dark: '#000000',
    light: '#9FB9C9',
    fontFamily: 'Montserrat',
    lineHeight: 1.6,
    fontSize: 14,
    h1: {
      fontSize: '48px',
      lineHeight: '48px',
      fontWeight: 500,
      color: '#6E6F72',
    },
    h2: {
      fontSize: '36px',
      lineHeight: '48px',
      fontWeight: 500,
      color: '#6E6F72',
    },
    body1: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 400,
      color: '#6E6F72',
    },
    body2: {
      fontSize: '12px',
      lineHeight: '24px',
      fontWeight: 400,
      color: '#6E6F72',
    },
  },
  gradients: {
    green: 'linear-gradient(124.78deg, #e0efc5 1.37%, #569127 101.37%)',
    grey: 'linear-gradient(180deg, #9FB9C9 0%, #424242 100%)',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
                *, *::before, *::after {
                    box-sizing: inherit
                }
            
                html: {
                    box-sizing: border-box
                }

                body {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #ffffff !important;
                }
            `,
      breakpoints: {
        values: {
          xs: 400,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
    },
  },
} as const;

export default createTheme(theme);

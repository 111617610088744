import { createContext } from 'react';

interface SurveyContextType {
  sessionId: string | null;
  setSessionId: (id: string) => void;
}

export const SurveyContext = createContext<SurveyContextType>({
  sessionId: localStorage.getItem('SESSION_ID'),
} as SurveyContextType);
export const ServeyProvider = SurveyContext.Provider;

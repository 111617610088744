import {
  Grid,
  Paper,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';
import React from 'react';
import FormContainer from '../layouts/FormContainer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import theme from '../../styles/theme';
import { Field } from 'formik';

export type CheckBoxFormProps = {
  title: string;
  description: string;
  answers: {
    id: number;
    questionId: number;
    position: number;
    answerLanguages: {
      title: string;
      hint: string;
    }[];
  }[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  id: number;
  isMulti?: boolean;
  values: any;
};

const CheckBoxForm: React.FC<CheckBoxFormProps> = ({
  answers,
  title,
  description,
  setFieldValue,
  id,
  isMulti,
}) => {
  const theme = useTheme();
  const mobileOnly = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FormContainer>
      <Typography variant={mobileOnly ? 'body1' : 'h1'}>{title}</Typography>
      <Typography variant={mobileOnly ? 'body2' : 'h2'}>
        {description}
      </Typography>
      <Grid container spacing='1rem'>
        {answers.map((el: any) => (
          <Grid key={el.id} item xs={12} md={6} lg={4}>
            <Field name={id}>
              {({ field }: any) => (
                <Item
                  sx={{ minHeight: '100%' }}
                  {...field}
                  name={id}
                  value={field.value}
                  onClick={() => {
                    if (!isMulti) {
                      setFieldValue(id.toString(), el.id);
                      return;
                    }

                    if (field.value.includes(el.id)) {
                      const filtered = field.value.filter(
                        (existingId: number) => existingId !== el.id
                      );
                      setFieldValue(id.toString(), filtered);
                    } else {
                      setFieldValue(id.toString(), [...field.value, el.id]);
                    }
                  }}
                  id={
                    field.value.toString().includes(el.id.toString())
                      ? 'container-checked'
                      : ''
                  }
                >
                  <CheckCircleIcon
                    htmlColor={
                      field.value.toString().includes(el.id.toString())
                        ? 'rgb(151 201 64 )'
                        : 'rgb(217 217 217)'
                    }
                    fontSize='large'
                  />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='body1'>
                      {el.answerLanguages[0].title}
                    </Typography>
                    {el.answerLanguages[0].hint && (
                      <Typography variant='body2'>
                        {el.answerLanguages[0].hint}
                      </Typography>
                    )}
                  </Box>
                </Item>
              )}
            </Field>
          </Grid>
        ))}
      </Grid>
      {isMulti ? (
        <Typography>
          If nothing is applicable to your case, you can move to the next step
        </Typography>
      ) : null}
    </FormContainer>
  );
};

const Item = styled(Paper)({
  flex: 1,
  padding: '1.5rem 2rem',
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  borderRadius: '1rem',
  color: 'rgb(151, 201, 64)',
  cursor: 'pointer',
  boxShadow: 'none',
  border: '1px solid gray',
  transition: '0.1s all',

  '&:hover': {
    transform: 'scale(1.04)',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },

  '&#container-checked': {
    boxShadow: 'none',
    border: '1px solid green',
    backgroundColor: theme.palette.primary.light,
  },
});

export default CheckBoxForm;

import { useEffect, useState } from 'react';

import { LanguageT, TranslationT } from '../types';
import { lang } from '../api';

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift();
  }
};

export function useTranslations() {
  const [translationList, setTranslationList] = useState<
    LanguageT[] | undefined
  >();
  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguageT | undefined
  >();
  const [translationData, setTranslationData] = useState<
    TranslationT[] | undefined
  >();

  useEffect(() => {
    // Add cookie manualy for testing, and so app doesnt crash
    // document.cookie = 'wp-wpml_current_language=en';

    lang.all().then((res) => setTranslationList(res.data));
  }, []);

  useEffect(() => {
    if (!translationList) return;

    const cookieValue = getCookie('wp-wpml_current_language');

    let lang;
    if (cookieValue) {
      lang = translationList.find((el) => el.code.includes(cookieValue));
    }

    if (!lang) {
      lang = translationList[0];
    }

    setSelectedLanguage(lang);
    localStorage.setItem('LANG_ID', lang.id.toString());
  }, [translationList]);

  useEffect(() => {
    if (selectedLanguage) {
      lang
        .translations(selectedLanguage?.id)
        .then((res) => setTranslationData(res.data));
    }
  }, [selectedLanguage]);

  return {
    translationList,
    selectedLanguage,
    translationData,
  };
}

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import { form } from '../../api';
import { SurveyContext } from '../../context/survey';
import { ResultT } from '../../types';
import DeveloperList from './DeveloperList';
import AppOverview from './AppOverview';

const classes = {
  text: {
    fontSize: '26px',
    marginTop: '24px',
    width: '100%',
  },
  paddingBotTop: {
    paddingTop: '10px',
    paddingBotton: '10px',
  },
  textCenter: {
    textAlign: 'center',
  },
  greenBtn: {
    backgroundColor: '#9acb42',
    color: '#fff',
    marginTop: '2rem',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: '#52790d',
    },
  },
  cardContent: {
    textAlign: 'center',
    display: 'grid',
    justifyItems: 'center',
  },
  card: {
    minWidth: '250px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    broder: 'none',
  },
  gridItem: {
    alignItems: 'center',
  },
  reqDevs: {
    minWidth: '270px',
  },
  overviewContainer: {
    margin: '20px auto',
  },
};

const Result = () => {
  const { setSessionId } = useContext(SurveyContext);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [result, setResult] = useState<ResultT>({} as ResultT);
  const [error, setError] = useState<boolean>(false);
  const [, navigate] = useLocation();
  const [, params] = useRoute('/result/:id');
  const {
    minCost,
    minHourSum,
    maxHourSum,
    maxCost,
    requiredDevelopers = [],
    answers = [],
  } = result;

  useEffect(() => {
    const current_id = params && params.id;

    if (!current_id) return;

    form
      .result(current_id)
      .then((res) => {
        setResult(res.data);
        // localStorage.removeItem('session_id');
        // localStorage.removeItem('lang_id');
        // localStorage.removeItem('values');
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }, []);

  if (error) {
    return (
      <>
        <Box
          sx={{
            display: 'grid',
            justifyItems: 'center',
          }}
        >
          <Typography align='center' mt='60px' mb='60px'>
            Report you are trying to access does not exist.
          </Typography>
          <Button
            variant='contained'
            sx={{ color: 'white' }}
            onClick={() => navigate('/')}
          >
            Go to Form
          </Button>
        </Box>
      </>
    );
  }

  const SkeletonTextComponent = () => {
    return (
      <>
        <Typography variant='h6' width={70}>
          <Skeleton />
        </Typography>
        <Typography variant='h1' sx={{ mt: '10px', width: '100%' }}>
          <Skeleton />
        </Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '10px',
      }}
    >
      <Grid
        container
        spacing={4}
        justifyContent='center'
        alignItems='center'
        p={2}
        direction='column'
        maxWidth={{ xs: '100%', sm: '80%', md: '71%' }}
      >
        {/* <Grid item sx={classes.gridItem}>
          <Card sx={classes.card}>
            <CardContent sx={classes.cardContent}>
              {minCost && maxCost ? (
                <>
                  <Typography>Cost estimate</Typography>
                  <Typography sx={classes.text}>
                    ${minCost.toLocaleString()} - ${maxCost.toLocaleString()}
                  </Typography>
                </>
              ) : (
                <>
                  <SkeletonTextComponent />
                </>
              )}
            </CardContent>
          </Card>
        </Grid> */}

        {/* <Grid item>
          <Card sx={classes.card}>
            <CardContent sx={classes.cardContent}>
              {minHourSum && maxCost ? (
                <>
                  <Typography>Project time</Typography>
                  <Typography sx={classes.text}>
                    {minHourSum} - {maxHourSum} (h)
                  </Typography>
                </>
              ) : (
                <>
                  <SkeletonTextComponent />
                </>
              )}
            </CardContent>
          </Card>
        </Grid> */}
        <Grid sx={classes.textCenter}>
          <Typography pt={5}>
            Thank you for taking time to fill in this form! Below you can see
            the overall specification of your app, as well as the developers
            needed to create it.
            <br />
            <br />
            All these information, along with time estimate, are sent to your
            email as well, feel free to check it out!
          </Typography>
        </Grid>

        <Grid item sx={classes.reqDevs}>
          <DeveloperList
            requiredDevelopers={requiredDevelopers.sort(
              (a, b) => a.position - b.position
            )}
          />
        </Grid>
        <Grid
          width={{ xs: '104%', sm: '100%', md: '70%' }}
          sx={classes.overviewContainer}
        >
          <AppOverview answers={answers} />
        </Grid>
        {/* <Button
          variant='contained'
          sx={{ color: 'white', marginTop: '2rem' }}
          href={'mailto:sales@quantox.com'}
        >
          Contact sales
        </Button> */}
        <Typography align='center'>
          While we are looking into everything you just sent us, take a look at
          case studies to see what we have built for our clients.
        </Typography>
        <a href='https://www.quantox.com/case-studies'>
          <Button variant='outlined' sx={classes.greenBtn}>
            Read our case studies
          </Button>
        </a>
        <Typography align='center' sx={{ marginTop: '1rem' }}>
          Once we evaluate the information you entered, we will reach out to you
          in no time!
        </Typography>
        <Button
          variant='outlined'
          sx={{ marginTop: '2rem' }}
          onClick={() => {
            localStorage.removeItem('values');
            localStorage.removeItem('SESSION_ID');
            setSessionId('');
            navigate('/');
          }}
        >
          Request a new estimation
        </Button>
      </Grid>
    </Box>
  );
};

export default Result;

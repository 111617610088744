import { FC } from 'react';
import { FiberManualRecord } from '@mui/icons-material';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Skeleton,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

interface overviewList {
  answers: {
    id: number;
    question: {
      id: number;
      position: number;
      questionLanguages: {
        title: string;
      }[];
    };
    answerLanguages: {
      title: string;
    }[];
  }[];
}

const AppOverview: FC<overviewList> = ({ answers }) => {
  const mapReverse = answers
    .map((answer) => {
      return answer;
    })
    .reverse();
  return (
    <>
      <Typography variant='h2' sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
        Application Overview
      </Typography>
      <Divider />
      <List>
        <ListItem>
          <Typography sx={{ width: '63%' }} variant='h5'>
            Questions:
          </Typography>
          <Typography sx={{ width: '25%' }} variant='h5'>
            Answers:
          </Typography>
        </ListItem>
      </List>
      <List sx={{ margin: '0 auto' }}>
        {mapReverse.map((answer) => {
          return (
            <>
              <Grid>
                <ListItem
                  sx={{
                    padding: '10px',
                    margin: '5px',
                    border: '1px solid limegreen',
                    borderRadius: '10px',
                  }}
                  key={answer.id}
                >
                  <ListItemText
                    sx={{ width: '53%' }}
                    primary={answer.question.questionLanguages[0].title}
                  />

                  <ListItemText
                    sx={{ width: '25%' }}
                    primary={answer.answerLanguages[0].title}
                  />
                </ListItem>
              </Grid>
            </>
          );
        })}
      </List>
    </>
  );
};
export default AppOverview;
